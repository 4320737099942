<template>
  <div :class="{ about: true, 'd-column': windowWidth < 800 }">
    <div :class="{ 'about-column-img': true, 'w-100': windowWidth < 800 }">
      <img
        alt="About Us"
        class="about-image"
        src="@/assets/background/about_image.svg"
      />
    </div>

    <div :class="{ 'about-column': true, 'w-100': windowWidth < 800 }">
      <div class="about-indicator">
        <label class="about-indicator-label">About Us</label>
      </div>
      <h2 class="about-heading">Welcome to PHISOFT</h2>
      <h5 class="about-subheading">by PSG Group</h5>
      <label class="about-description"
        >PHISOFT is a tech company not only a software house. We focus on
        manufacturing and development of the technology and provide technology
        as a service.</label
      >
      <button @click="readMore">Read more</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    windowWidth: Number,
  },
})
export default class About extends Vue {
  windowWidth!: number;
  readMore() {
    this.$router.push({
      path: '/about',
    });
  }
}
</script>

<style scoped>
.about {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
}

.d-column {
  flex-direction: column;
}

.about-column-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 40%;
}
.about-image {
  width: 100%;
  height: 100%;
  max-width: 408px;
  max-height: 528px;
  object-fit: contain;
}

.about-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 60%;
}

.about-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.about-indicator-label {
  color: var(--primary);
  padding-left: 12px;
  position: relative;
}

.about-indicator-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: var(--primary);
  border-radius: 2px;
}

.about-heading {
  margin-bottom: 0;
}

.about-subheading {
  margin-top: 0;
  margin-bottom: 24px;
}
.about-description {
  margin-bottom: 24px;
}

.w-100 {
  width: 100%;
}
</style>
