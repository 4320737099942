<template>
  <div :class="{ introduction: true, 'd-column': windowWidth < 800 }">
    <div :class="{ 'introduction-column': true, 'w-100': windowWidth < 800 }">
      <h2
        class="introduction-heading"
        :style="windowWidth < 800 ? 'color: white' : ''"
      >
        Introduce your Bussiness by using Software
      </h2>
      <label
        :style="windowWidth < 800 ? 'color: white' : ''"
        class="introduction-description"
        >Take your business to a new level by utilizing our technology. We would
        like to have you share your hardships with us and we will gladly solve
        all your problems
      </label>
      <button @click="startChat">Chat with Us</button>
    </div>
    <div
      v-if="windowWidth >= 800"
      :class="{ 'introduction-column-img': true, 'w-100': windowWidth < 400 }"
    >
      <img
        alt="Introduction"
        class="introduction-image"
        src="@/assets/phisoft-intro-image.webp"
      />
    </div>
    <img
      class="meteor-img"
      v-if="windowWidth < 800"
      src="@/assets/background/meteor-bg.svg"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    windowWidth: Number,
  },
})
export default class Introduction extends Vue {
  windowWidth!: number;
  startChat() {
    window.open('https://wa.me/message/7JUS47ZVJJPAO1', '_blank');
  }
}
</script>

<style scoped>
.introduction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 90px;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
}

.d-column {
  flex-direction: column;
}

.meteor-img {
  position: absolute;
  z-index: -1;
  inset: 0;
  object-fit: cover;
  width: 100vw;
  height: 100%;
}

.introduction-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 60%;
  padding-top: 40px;
}

.introduction-column-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 40%;
  padding-top: 30px;
}

.introduction-heading {
  margin-bottom: 24px;
  /* padding-top: 40px; */
}

.introduction-description {
  margin-bottom: 24px;
}

.introduction-image {
  width: 100%;
  height: 100%;
  max-width: 408px;
  max-height: 528px;
  object-fit: contain;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .introduction {
    padding: 0 30px;
    padding-bottom: 20px;
  }
}
</style>
