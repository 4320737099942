<template>
  <div class="choose-us">
    <img alt="" src="@/assets/circuit-bg-2.svg" class="circuit-img" />
    <div :class="{ 'row-space': true, 'd-column': windowWidth < 800 }">
      <div class="column">
        <div class="choose-us-indicator">
          <label class="choose-us-label">Why choose us</label>
        </div>

        <h3 class="choose-us-heading">Finding the right fit, every time</h3>
        <label class="choose-us-body"
          >Tailored solutions, seasoned team, fueled by passion. Stay ahead with
          our tech-savvy approach.</label
        >

        <div class="row">
          <img
            alt="Experienced Team"
            class="icon"
            src="@/assets/icon/Experience.png"
          />
          <div class="column">
            <label class="choose-us-body">Experienced Team</label>
            <label class="choose-us-body"
              >Our team has years of experience in the software & technology
              field</label
            >
          </div>
        </div>
        <div class="row">
          <img alt="Passion" class="icon" src="@/assets/icon/Passion.png" />
          <div class="column">
            <label class="choose-us-body">Passion</label>
            <label class="choose-us-body"
              >Yes, this is our passion so we will create something best we can
              do.</label
            >
          </div>
        </div>
        <div class="row">
          <img alt="Up To Date" class="icon" src="@/assets/icon/UptoDate.png" />
          <div class="column">
            <label class="choose-us-body">Stay Up-To-Date</label>
            <label class="choose-us-body"
              >We pride ourselves on staying up-to-date with the latest
              technologies and trends.</label
            >
          </div>
        </div>
      </div>
      <img
        alt="Choose Us"
        class="choose-us-image"
        src="@/assets/background/choose_us_bg.svg"
      />
    </div>
    <div :class="{ 'row-space': true, 'd-column-r': windowWidth < 800 }">
      <img
        alt="Work Flow"
        class="choose-us-image"
        src="@/assets/background/work_flow_bg.svg"
      />
      <div class="column">
        <div class="choose-us-indicator">
          <label class="choose-us-label">Work Flow</label>
        </div>

        <h3 class="choose-us-heading">Our Working Process</h3>
        <label class="choose-us-body"
          >We harness the power of Agile methodology to ensure optimal results,
          fostering collaboration, adaptability, and iterative development,
          ultimately delivering superior outcomes tailored to your needs</label
        >

        <div class="row">
          <img alt="Planning" class="icon" src="@/assets/icon/Experience.png" />
          <div class="column">
            <label class="choose-us-body">01 - Planning</label>
          </div>
        </div>

        <div class="row">
          <img alt="Execution" class="icon" src="@/assets/icon/UptoDate.png" />
          <div class="column">
            <label class="choose-us-body">02 - Execution</label>
          </div>
        </div>
        <div class="row">
          <img alt="Review" class="icon" src="@/assets/icon/UptoDate.png" />
          <div class="column">
            <label class="choose-us-body">03 - Review</label>
          </div>
        </div>
        <div class="row">
          <img alt="Repeat" class="icon" src="@/assets/icon/Passion.png" />
          <div class="column">
            <label class="choose-us-body">04 - Repeat</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    windowWidth: Number,
  },
})
export default class ChooseUs extends Vue {
  windowWidth!: number;
}
</script>

<style scoped>
.choose-us {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
  background-color: var(--primary);
  position: relative;
}

.circuit-img {
  position: absolute;
  top: 0;
  left: 0;
}

.row-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.choose-us-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 32px;
}

.choose-us-label {
  color: white;
  padding-left: 12px;
  position: relative;
}

.choose-us-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: white;
  border-radius: 2px;
}

.choose-us-heading {
  margin-bottom: 16px;
  color: white;
  text-align: left;
}

.choose-us-image {
  width: 40%;
  height: 100%;
}

.choose-us-body {
  color: white;
  text-align: left;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
}

.icon {
  width: 64px;
  height: 64px;
  margin-right: 12px;
}

.d-column {
  flex-direction: column;
}

.d-column-r {
  flex-direction: column-reverse;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .column {
    width: 100%;
  }
  .choose-us-image {
    width: 60%;
  }
}
</style>
