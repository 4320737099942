<template>
  <div
    :class="{
      'top-navigation-container': true,
    }"
  >
    <img
      class="phisoft-logo-image"
      alt="logo-image"
      src="./assets/logo-phisoft-full-horizontal.webp"
    />
    <nav v-if="windowWidth >= 800">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About Us</router-link>
      <router-link to="/contact">Contact</router-link>
    </nav>
    <div v-else class="menu-button" @click="showNavigationBar()">
      <div class="menu-bar"></div>
      <div class="menu-bar"></div>
      <div class="menu-bar"></div>
    </div>
    <div
      :class="{ 'navbar-mobile': true, 'navbar-active': navBar.ani }"
      v-if="navBar.show"
      @click="showNavigationBar()"
    >
      <div
        :class="{ 'navigation-button': true, 'navigation-active': navBar.ani }"
      >
        <router-link to="/">Home</router-link>
        <router-link to="/about">About Us</router-link>
        <router-link to="/contact">Contact</router-link>
      </div>
    </div>
  </div>
  <div class="small-light-blue"></div>
  <div class="small-blue"></div>
  <img
    v-if="windowWidth >= 800"
    alt=""
    role="presentation"
    class="blue-circle"
    src="@/assets/Background-blue-circle.webp"
  />
  <img
    alt=""
    role="presentation"
    src="@/assets/circuit-bg-3.svg"
    class="circuit-top"
  />
  <img
    alt=""
    role="presentation"
    src="@/assets/circuit-bg.svg"
    class="circuit-bottom"
  />
  <router-view />

  <div class="footer-container">
    <div :class="{ 'f-container': true }">
      <div class="f-company">
        <div class="f-column-info">
          <h3>PHISOFT</h3>
          <label>PT Pasifik Hoki Indonesia, A member of PSG Group</label>
        </div>

        <div class="f-row">
          <a
            href="https://instagram.com/phisoft.id"
            aria-label="Follow us on Instagram"
          >
            <img
              alt="Follow us on Instagram"
              class="f-icon-info"
              src="@/assets/icon/insta.svg"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/phisoft-id/"
            aria-label="Follow us on Linkedin"
          >
            <img
              alt="Follow us on Linkedin"
              class="f-icon-info"
              src="@/assets/icon/linked.svg"
            />
          </a>
        </div>
      </div>
      <div class="f-info">
        <div class="f-column-info">
          <img alt="Phone" src="@/assets/icon/phone.svg" class="f-icon-info" />
          <img alt="Email" src="@/assets/icon/email.svg" class="f-icon-info" />
          <img
            alt="Address"
            src="@/assets/icon/location_pin.svg"
            class="f-icon-info"
          />
        </div>
        <div class="f-column-info">
          <label class="f-label-info"> (081) 11623222</label>
          <label class="f-label-info"> halo@phisoft.co.id</label>
          <label class="f-label-info">
            Gold Coast Office, Liberty Tower OTB 20B, Pantai Indah Kapuk, Kota
            Jakarta Utara, Daerah Khusus Ibukota Jakarta, 14470 -
            Indonesia</label
          >
        </div>
      </div>
    </div>
    <div class="f-seperator" />
    <div :class="{ 'f-copyright': true }">
      <label>© Copyright {{ thisYear() }} PHISOFT | All Rights Reserved</label>
      <label>Operational Hours : Monday - Friday (09:00 - 16:00)</label>
    </div>
  </div>
</template>
<script lang="ts">
import './assets/Poppins/font.css';
import './custom.css';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { useMeta } from 'vue-meta';

export default class App extends Vue {
  windowWidth: number = window.innerWidth;
  navBar = {
    show: false,
    ani: false,
  };

  mounted() {
    window.addEventListener('resize', this.handleResize);

    useMeta({
      title: 'PHISOFT HOME',
      htmlAttrs: { lang: 'en', amp: true },
    });
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  @Watch('windowWidth')
  onWindowWidthChange(newValue: number, oldValue: number) {}

  handleResize() {
    this.windowWidth = window.innerWidth;
  }

  thisYear(): number {
    return new Date().getFullYear();
  }

  showNavigationBar() {
    if (this.navBar.show) {
      this.navBar.ani = false;
      setTimeout(() => {
        this.navBar.show = false;
      }, 300);
    } else {
      this.navBar.show = true;
      setTimeout(() => {
        this.navBar.ani = true;
      }, 1);
    }
  }
}
</script>

<style>
#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  overflow-x: hidden;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.top-navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px;
}

.phisoft-logo-image {
  width: 167px;
  height: 44px;
  object-fit: contain;
}

nav {
  padding: 30px;
}

.white-background {
  background-color: white;
}

.navbar-mobile {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: 300ms;
  opacity: 0;
  z-index: 20;
}

.navbar-active {
  opacity: 1;
}

.navigation-button {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 20px;
  padding-top: 40px;
  font-weight: 400;
  background-color: white;
  transition: 300ms;
  transform: translateX(100px);
}

.navigation-active {
  transform: translateX(0px);
  transition: 300ms;
}

.navigation-button a {
  padding: 16px 128px;
  color: var(--primary);
  text-decoration: none !important;
}

.navigation-button a.router-link-exact-active {
  text-decoration: underline !important;
}
nav a {
  font-weight: bold;
  color: white;
  font-size: 20px;
  font-weight: 400;
  padding-left: 64px;
  text-decoration: none !important;
}

nav a.router-link-exact-active {
  color: var(--secondary);
}

.hidden-class {
  display: none;
}

.menu-button {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.menu-bar {
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: var(--primary);
  margin: 2px 0;
}

.circuit-top {
  position: absolute;
  top: 492px;
  right: 0;
  z-index: -3;
}

.circuit-bottom {
  position: absolute;
  top: 756px;
  right: 0;
  z-index: -3;
}

.small-light-blue {
  background-color: var(--secondary);
  border: 8px solid white;
  position: absolute;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  top: 180px;
  left: -30px;
  z-index: -1;
}

.small-blue {
  background-color: var(--primary);
  position: absolute;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  top: 212px;
  left: -150px;
  z-index: -2;
}

.blue-circle {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: -160px;
  right: -160px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 40px;
  background-color: white;
}

.f-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.f-copyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.f-column {
  flex-direction: column;
}

.f-column-r {
  flex-direction: column-reverse;
}

.f-company {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
}

.f-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.f-info {
  display: flex;
  flex-direction: row;
  max-width: 400px;
}
.f-column-info {
  display: flex;
  flex-direction: column;
}
.f-icon-info {
  margin-right: 16px;
  margin-bottom: 10px;
  width: 24px;
  height: 24px;
}

.f-label-info {
  margin-bottom: 10px;
  line-height: 24px;
  text-align: left;
}

.f-seperator {
  width: 100%;
  height: 2px;
  background-color: var(--primary);
  margin: 30px 0;
}

@media screen and (max-width: 800px) {
  .top-navigation-container {
    background-color: white;
  }
  .f-container {
    flex-direction: column;
  }

  .f-copyright {
    flex-direction: column-reverse;
  }
}
</style>
