<template>
  <div class="home">
    <Introduction :windowWidth="windowWidth" />
    <About :windowWidth="windowWidth" />
    <Product :windowWidth="windowWidth" />
    <OurClient :windowWidth="windowWidth" />
    <ChooseUs :windowWidth="windowWidth" />
    <!-- <Map /> -->
  </div>
</template>

<script lang="ts">
import Introduction from '@/components/Home/Introduction.vue';
import About from '@/components/Home/About.vue';
import Product from '@/components/Home/Product.vue';
import OurClient from '@/components/Home/OurClient.vue';
import ChooseUs from '@/components/Home/ChooseUs.vue';
import Map from '@/components/Home/Map.vue';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    Introduction,
    About,
    Product,
    OurClient,
    ChooseUs,
    Map,
  },
})
export default class HomeView extends Vue {
  windowWidth: number = window.innerWidth;

  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  @Watch('windowWidth')
  onWindowWidthChange(newValue: number, oldValue: number) {}

  handleResize() {
    this.windowWidth = window.innerWidth;
  }
}
</script>
